globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"f3ec69919ec1fabf370fb27625220e9628fefa81"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN =
  process.env.SENTRY_DSN ||
  process.env.NEXT_PUBLIC_SENTRY_DSN ||
  "https://b0053c5c44dc4c41bb93da313a117392@o1255691.ingest.sentry.io/6424332";

Sentry.init({
  dsn: SENTRY_DSN,
  enabled: process.env.NODE_ENV === "production",
  tracesSampleRate: process.env.NODE_ENV === "development" ? 0.0 : 0.2,
  ignoreErrors: [
    /*
     * Error inside the session replay from PostHog
     */
    "Cannot parse given Error object",
    /*
     * Error from the browser when trying to autoplay a video, is usually only allowed when:
     * - The video is muted or volume is set to 0
     * - The user has interacted with the site (by clicking, tapping, pressing keys, etc.)
     * - If the site has been whitelisted; this may happen either automatically
     *   if the browser determines that the user engages with media frequently
     */
    "The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.",
  ],
});
